import React, { Fragment, useReducer, useMemo, useEffect, useState } from 'react';
import NewAdvanceTextInput from "../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import { useDispatch, useSelector } from 'react-redux';
import { patchCompanyItem } from '../../../../../store/actions';
import { useFirstRender } from '../../../../../hooks/useFirstRender';
import FormEditButtonArea from "../../../../Ui/Inputs/FormEditButtonArea/FormEditButtonArea";
import NewAdvanceDateTimePicker from "../../../../Ui/Inputs/NewAdvanceDateTimePicker/NewAdvanceDateTimePicker";
import { Col, OverlayTrigger, Popover, Button, Collapse } from 'react-bootstrap';
import moment from 'moment';
import BootstrapRowColCombo from "../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewAdvanceDropdown from "../../../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import NewAdvanceSliderButton from "../../../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import classes from './CompanyPlanForm.module.css';
import {
    isNumber,
    isSuccessOrNull
} from '../../../../../utils/validation';
import ReactivatePlanModal from '../../../../Modals/ReactivePlanModal/ReactivatePlanModal';
import { deleteCreditPlan, getAvailableCreditPlans } from '../../../../../utils/services/turboPlus2RestCalls';
import PlanCard from './PlanCard/PlanCard';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { centsToDollars } from '../../../../../utils/misc';
import { showToastSuccess, showToastError } from '../../../../../utils/toastMessages';


const formTypes = {
    company_plan: 'company_plan',
    credit_pricing: 'credit_pricing',
    payment_method: 'payment_method'
}

const init = company => {
    return {
        formType: '',
        inCompanyPlanEditMode: false,
        inCreditPricingEditMode: false,
        addCreditPlanEditMode: false,
        inPaymentMethodEditMode: false,
        loading: false,
        submitDisabled: true,
        activeRoleLimit: company.active_role_limit ? company.active_role_limit.toString() : '',
        planStartDate: company.plan_start_date ? moment.utc(company.plan_start_date).toDate() : null,
        planRenewalDate: company.customer_pricing_plan && company.customer_pricing_plan.next_billing_date ? new Date(company.customer_pricing_plan.next_billing_date.split('T')[0] + 'T00:00:00') : null,
        planEndDate: company.plan_end_date ? moment.utc(company.plan_end_date).toDate() : null,
        cancelationDate: company.customer_pricing_plan && company.customer_pricing_plan.cancelation_date ? moment.utc(company.customer_pricing_plan.cancelation_date).toDate() : null,
        billingCycle: company.customer_pricing_plan ? company.customer_pricing_plan.subscription_type : 'Annual',
        requiresReactivation: company.customer_pricing_plan && company.customer_pricing_plan.requires_reactivation ? true : false,
        hearAboutUs: company.customer_pricing_plan ? company.customer_pricing_plan.how_did_you_hear_about_us : null,
        card_info: company.card_info,
        typeOfPlan: company.role_type_id ? company.role_type_id : company.role_type.id,
        isFreeTrial: company.customer_pricing_plan && company.customer_pricing_plan.subscription_type === 'Freemium' ? true : false,
        defaultPaymentMethod: company.invoice ? 'inv' : 'cc',

        isCreditPricing: company.is_credit_pricing,
        customerCreditPlan: company.customerCreditPlan ? company.customerCreditPlan : null,
        availableCreditPlans: [],
        isLoadingAvailableCreditPlans: false,
        // Credit Pricing Inputs
        creditPlanInputs: {
            candidate_volume: '',
            cost_per_candidate: '',
            billing_type: 'monthly',
            id: null,
            name: ''
        },

        validation: {
            activeRoleLimitValidState: null,
            validateActiveRoleLimit: false,
            pricePerMonthValidState: null,
            validatePricePerMonth: false,
            // Credit Pricing Validation
            candidateVolumeValidState: null,
            validateCandidateVolume: false,
            costPerCandidateValidState: null,
            validateCostPerCandidate: false,
            planNameValidState: null,
            validatePlanName: false
        },
        ...(company.customer_pricing_plan ? { pricePerMonth: company.customer_pricing_plan.amount } : {}),
        isCustomPlansCollapsed: true,
        isDefaultPlansCollapsed: true,
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'companyPlanEditEnabled':
            return {
                ...state,
                inCompanyPlanEditMode: true,
                inCreditPricingEditMode: false,
                inPaymentMethodEditMode: false,
                addCreditPlanEditMode: false,
                formType: formTypes.company_plan,
            }
        case 'creditPricingEditEnabled':
            return {
                ...state,
                inCreditPricingEditMode: true,
                inCompanyPlanEditMode: false,
                inPaymentMethodEditMode: false,
                addCreditPlanEditMode: false,
                formType: formTypes.credit_pricing,
            }
        case 'addPlanEditEnabled':
            return {
                ...state,
                inCreditPricingEditMode: true,
                addCreditPlanEditMode: true,
                inCompanyPlanEditMode: false,
                inPaymentMethodEditMode: false,
                formType: formTypes.credit_pricing,
            }
        case 'paymentMethodEditEnabled':
            return {
                ...state,
                inPaymentMethodEditMode: true,
                inCompanyPlanEditMode: false,
                inCreditPricingEditMode: false,
                addCreditPlanEditMode: false,
                formType: formTypes.payment_method,
            }

        case 'loading':
            return {
                ...state,
                loading: true
            }
        case 'loadingAvailableCreditPlans':
            return {
                ...state,
                isLoadingAvailableCreditPlans: true
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'activeRoleLimitChanged':
            const isValid = isNumber(action.payload);
            return {
                ...state,
                activeRoleLimit: action.payload,
                validation: {
                    ...state.validation,
                    activeRoleLimitValidState: isValid ? 'success' : 'error',
                    validateActiveRoleLimit: true
                }
            }
        case 'planStartDateChanged':
            return {
                ...state,
                planStartDate: action.payload
            }
        case 'planEndDateChanged':
            return {
                ...state,
                planEndDate: action.payload
            }
        case 'forceCollectCardChanged':
            return {
                ...state,
                forceCollectCard: action.payload
            }
        case 'typeOfPlanChanged':
            return {
                ...state,
                typeOfPlan: action.payload
            }
        case 'paymentMethodChanged':
            return {
                ...state,
                defaultPaymentMethod: action.payload
            }
        case 'billingCycleChanged':
            return {
                ...state,
                billingCycle: action.payload
            }
        case 'hearAboutUsChanged':
            return {
                ...state,
                hearAboutUs: action.payload
            }
        case 'pricePerMonthChanged':
            return {
                ...state,
                pricePerMonth: action.payload,
            }
        case 'isCreditPricingChanged':
            return {
                ...state,
                isCreditPricing: action.payload,
            }
        case 'candidateVolumeChanged':
            const isValidVolume = isNumber(action.payload);
            return {
                ...state,
                creditPlanInputs: {
                    ...state.creditPlanInputs,
                    candidate_volume: action.payload
                },
                validation: {
                    ...state.validation,
                    candidateVolumeValidState: isValidVolume ? 'success' : 'error',
                    validateCandidateVolume: true
                }
            }

        case 'costPerCandidateChanged':
            // Validates numbers with up to 2 decimal places
            const regex = /^\d*\.?\d{0,2}$/;
            const isValidCost = regex.test(action.payload);

            return {
                ...state,
                creditPlanInputs: {
                    ...state.creditPlanInputs,
                    cost_per_candidate: action.payload,
                },
                validation: {
                    ...state.validation,
                    costPerCandidateValidState: isValidCost ? 'success' : 'error',
                    validateCostPerCandidate: true
                }
            }
        case 'planNameChanged':{
            const isValid = action.payload.length > 0 && action.payload.length <= 70;
            return {
                ...state,
                creditPlanInputs: {
                    ...state.creditPlanInputs,
                    name: action.payload
                },
                validation: {
                    ...state.validation,
                    planNameValidState: isValid ? 'success' : 'error',
                    validatePlanName: true
                }
            }
        }
        case 'billingTypeChanged':
            return {
                ...state,
                creditPlanInputs: {
                    ...state.creditPlanInputs,
                    billing_type: action.payload
                }
            }
        case 'resetCreditPricingForm':
            return {
                ...state,
                inCreditPricingEditMode: false,
                addCreditPlanEditMode: false,
                creditPlanInputs: {
                    candidate_volume: '',
                    cost_per_candidate: '',
                    billing_type: 'monthly',
                    id: null
                }
            }
        case 'creditPlanItemSelected':
            const selectedItem = state.availableCreditPlans.find(x => x.id === action.payload);

            return {
                ...state,
                inCreditPricingEditMode: true,
                addCreditPlanEditMode: true,
                creditPlanInputs: {
                    ...state.creditPlanInputs,
                    candidate_volume: selectedItem.candidate_volume.toString(),
                    cost_per_candidate: centsToDollars(selectedItem.cost_per_candidate).toString(),
                    name: selectedItem.name,
                    id: selectedItem.id
                }
            }
        case 'ON_DELETE_COMPANY_CREDIT_PLAN':
            return {
                ...state,
                availableCreditPlans: state.availableCreditPlans.filter(x => x.id !== action.payload)
            }
        case 'availableCreditPlansReceived':
            return {
                ...state,
                availableCreditPlans: Array.isArray(action.payload) ? action.payload : Object.values(action.payload),
                isLoadingAvailableCreditPlans: false
            }
        case 'reset':
            const resetedState = init(action.payload);
            return {
                ...resetedState,
            }
        case 'toggleCustomPlans':
            return {
                ...state,
                isCustomPlansCollapsed: !state.isCustomPlansCollapsed
            }
        case 'toggleDefaultPlans':
            return {
                ...state,
                isDefaultPlansCollapsed: !state.isDefaultPlansCollapsed
            }
        default:
            throw new Error();
    }
}

const CompanyPlanForm = ({ setProPlanMode }) => {
    const dispatchRedux = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const defaultCreditPlans = useSelector(state => state.companies.defaultCreditPlans);
    const company = useMemo(() => companies.find(x => x.id === companyId), [companies]);
    const [state, dispatch] = useReducer(reducer, company, init);

    const defaultPlans = state.customerCreditPlan ? defaultCreditPlans.filter(x => x.id !== state.customerCreditPlan.id) : defaultCreditPlans;
    const customPlans = state.customerCreditPlan ? state.availableCreditPlans.filter(x => x.customer_id === companyId && x.id !== state.customerCreditPlan.id) : state.availableCreditPlans;

    const isFirstRender = useFirstRender();
    const typeOfPlans = useSelector(state => state.roles.typeOfPlans)
    const paymentMethodsDropdown = [{ id: 'inv', name: "Invoice" }, { id: 'cc', name: "Credit Card" }]
    const billingCycleDropdown = [{ id: 'Month to Month', name: "Month to Month" }, { id: 'Annual', name: "Annual" }]
    const hearAboutUsOptions = [
        { id: null, name: "Select one" },
        { id: "Social Media (LinkedIn, Twitter, etc)", name: "Social Media (LinkedIn, Twitter, etc)" },
        { id: "Search Engines (Google, Yahoo, etc)", name: "Search Engines (Google, Yahoo, etc)" },
        { id: "Lever", name: "Lever" },
        { id: "Greenhouse", name: "Greenhouse" },
        { id: "ICIMs", name: "ICIMs" },
        { id: "SAP", name: "SAP" },
        { id: "Word of Mouth", name: "Word of Mouth" },
        { id: "Website", name: "Website" },
        { id: "Other", name: "Other" },
    ];


    const subscriptionTypePopOver = (
        <Popover id="subscription-type-popover">
            The 'Subscription Type' indicates whether the customer is on a month-to-month or yearly plan.
        </Popover>
    );

    const planRenewalDatePopOver = (
        <Popover id="plan-renewal-date-popover">
            The 'Plan Renewal Date' is the date when a plan is scheduled to renew unless canceled. For e-commerce plans, customers can
            choose between monthly or yearly plans. For manually created companies, we suggest setting the 'Plan Renewal Date' one year
            ahead of the 'Plan Start Date'.
        </Popover>
    );

    const PopoverHandler = ({ overlay }) => (
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="top"
            overlay={overlay}
        >
            <BsFillQuestionCircleFill className={classes.HelpIcon} />
        </OverlayTrigger>
    );

    useEffect(() => {
        if (company) {
            dispatch({ type: 'reset', payload: company });
        }
    }, [company]);

    // Shared cancel handler
    const handleEditCanceled = () => {
        dispatch({ type: 'reset', payload: company });
    }

    const handleCompanyPlanEditEnabled = () => {
        dispatch({ type: 'companyPlanEditEnabled' });
    }


    // Disable submit button if there are validation errors
    useEffect(() => {
        const {
            activeRoleLimitValidState,
            dateOverlapError,
            dateRangeError,
            candidateVolumeValidState,
            costPerCandidateValidState
        } = state.validation;

        if (
            isSuccessOrNull(activeRoleLimitValidState) &&
            !dateOverlapError &&
            !dateRangeError &&
            isSuccessOrNull(candidateVolumeValidState) &&
            isSuccessOrNull(costPerCandidateValidState)
        ) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [state.validation]);

    // Fetch available credit plans if there are no plans
    useEffect(() => {
        if (state.availableCreditPlans.length === 0 && !state.isLoadingAvailableCreditPlans) {
            dispatch({ type: 'loadingAvailableCreditPlans' });

            getAvailableCreditPlans(companyId)
                .then(response => {
                    dispatch({ type: 'availableCreditPlansReceived', payload: response });
                })
                .catch(error => {
                    console.error('Error fetching available credit plans:', error);
                });
        }
    }, [companyId, state.availableCreditPlans]);


    const handleSubmitChanges = () => {
        dispatch({ type: 'loading' });


        const payload = {
            form_type: state.formType,
            active_role_limit: state.activeRoleLimit === "" ? null : state.activeRoleLimit,
            plan_start_date: state.planStartDate === "" ? null : state.planStartDate,
            plan_end_date: state.planEndDate === "" ? null : state.planEndDate,
            role_type_id: parseInt(state.typeOfPlan, 10),
            price_per_month: state.pricePerMonth === "" ? null : state.pricePerMonth,
            invoice: state.defaultPaymentMethod === 'inv' ? true : false,
            force_collect_card: state.forceCollectCard,
        };

        let extraData = {
            card_info: state.card_info
        }

        dispatchRedux(patchCompanyItem(
            company.id,
            payload,
            extraData
        ));
    }

    const handleSubmitCreditPlanChanges = () => {
        dispatch({ type: 'loading' });
        const payload = {
            is_credit_pricing: state.isCreditPricing,
            form_type: 'credit_pricing',
        }
        if (
            state.isCreditPricing &&
            state.creditPlanInputs.candidate_volume &&
            state.creditPlanInputs.cost_per_candidate &&
            state.creditPlanInputs.billing_type &&
            state.creditPlanInputs.name
        ) {
            payload.credit_plan = {
                candidate_volume: parseInt(state.creditPlanInputs.candidate_volume, 10),
                cost_per_candidate: parseFloat(state.creditPlanInputs.cost_per_candidate), // send in dollars. It will be converted to cents in the backend´s model.
                billing_type: state.creditPlanInputs.billing_type,
                name: state.creditPlanInputs.name,
                status: 'active'
            }
        }


        // If there is an id, we are editing an existing credit plan.
        if (state.creditPlanInputs.id) {
            payload.credit_plan.id = state.creditPlanInputs.id;
        }

        //TODO: If isCreditPricing is false, we should ask for confirmation.

       dispatchRedux(patchCompanyItem(
            company.id,
            payload
        ))

    }

     // Credit Pricing handlers
     const handleCreditPricingEditEnabled = () => {
        dispatch({ type: 'creditPricingEditEnabled' });
    }

    // Payment Method handlers
    const handlePaymentMethodEditEnabled = () => {
        dispatch({ type: 'paymentMethodEditEnabled' });
    }

    const handleActiveRoleLimitChanged = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            dispatch({ type: 'activeRoleLimitChanged', payload: value });
        }
    }

    const handleBillingCycleChanged = (e) => {
        dispatch({ type: 'billingCycleChanged', payload: e.currentTarget.value });
    }

    const handleHearAboutUsChanged = (e) => {
        dispatch({ type: 'hearAboutUsChanged', payload: e.currentTarget.value });
    }

    const handleTypeOfPlanChanged = (e) => {
        dispatch({ type: 'typeOfPlanChanged', payload: e.currentTarget.value });
    }

    const handlePlanStartDateChanged = (value) => {
        dispatch({ type: 'planStartDateChanged', payload: value });
    }

    const handlePlanEndDateChanged = (value) => {
        dispatch({ type: 'planEndDateChanged', payload: value });
    }

    const handlePaymentMethodChanged = (e) => {
        dispatch({ type: 'paymentMethodChanged', payload: e.currentTarget.value });
    }

    const handleForceCollectCardChanged = (value) => {
        dispatch({ type: 'forceCollectCardChanged', payload: value });
    }

    const handlePricePerMonthChanged = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value) || value === "") {
            dispatch({ type: 'pricePerMonthChanged', payload: value });
        }
    }

    const isReactivating = state.inEditMode && company.is_from_drive_through && state.cancelationDate;
    const [showReactivatePlanModal, setShowReactivatePlanModal] = useState(false);

    const handleSaveButtonBehavior = () => {
        if (isReactivating) {
            setShowReactivatePlanModal(true);
        } else {
            handleSubmitChanges();
        }
    }

    const planRenewalDate = () => {
        if (isReactivating) {
            return null;
        }
        if (state.billingCycle === 'Month to Month') {
            return state.planRenewalDate;
        }
        return state.planEndDate;
    }

    const handleUpgradeCompany = () => {
        setProPlanMode(true);
    }

    const handleIsCreditPricingChanged = (value) => {
        dispatch({ type: 'isCreditPricingChanged', payload: value });
    }

    const handleCandidateVolumeChanged = (e) => {
        const value = e.target.value;
        dispatch({ type: 'candidateVolumeChanged', payload: value });
    }


    const handleCostPerCandidateChanged = (e) => {
        const value = e.target.value;

        dispatch({ type: 'costPerCandidateChanged', payload: value });
    }

    const handlePlanNameChanged = (e) => {
        const value = e.target.value;
        dispatch({ type: 'planNameChanged', payload: value });
    }


    const handleSelectCreditPlan = (id) => {
        dispatch({ type: 'creditPlanItemSelected', payload: id });
    }

    const handleClearCreditPricingForm = () => {
        dispatch({ type: 'resetCreditPricingForm' });
    }

    const handleBillingTypeChanged = (e) => {
        dispatch({ type: 'billingTypeChanged', payload: e.currentTarget.value });
    }

    const handleAddPlanEditEnabled = () => {
        dispatch({ type: 'addPlanEditEnabled' });
    }

    const handleDeleteCreditPlan = (id) => {
        try {
            deleteCreditPlan(companyId, id).then(() => {
                dispatch({ type: 'ON_DELETE_COMPANY_CREDIT_PLAN', payload: id });
                showToastSuccess('Credit plan deleted successfully');
            });
        } catch (error) {
            console.error('Error deleting credit plan:', error);
            showToastError('Error deleting credit plan');
        }
    }

    const toggleCustomPlans = () => {
        dispatch({ type: 'toggleCustomPlans' });
    };

    const toggleDefaultPlans = () => {
        dispatch({ type: 'toggleDefaultPlans' });
    };


    return (
        <>
            <Fragment>
                <FormEditButtonArea
                    editDisabled={state.loading}
                    inEditMode={state.inCompanyPlanEditMode}
                    onEditClicked={handleCompanyPlanEditEnabled}
                    onCancelClicked={handleEditCanceled}
                    onSaveClicked={handleSubmitChanges}
                    saveDisabled={state.submitDisabled}
                    editBtnText={(state.cancelationDate && company.is_from_drive_through) ? 'Reactivate subscription' : 'Edit'}
                    showEdit={(moment().isAfter(moment.utc(company.plan_end_date)) || !state.cancelationDate)}
                    title={state.isFreeTrial ? "Trial" : "Company Plan"}
                />

                <BootstrapRowColCombo>
                    <BootstrapRowColCombo>
                        <Col xs={12}>
                            <p className={classes.IsDriveThroughLabel}>
                                {company.is_from_drive_through ?
                                    state.isFreeTrial ?
                                        'This company activated its trial via e-commerce' :
                                        'This company was created via Rolebot\'s e-commerce' :
                                    'This company was created manually'
                                }
                            </p>
                        </Col>
                    </BootstrapRowColCombo>

                    {isReactivating && <BootstrapRowColCombo>
                        <Col xs={12}>
                            <div style={{ backgroundColor: '#FFC4001A', padding: 5, borderRadius: 4, marginBottom: '21px' }}>
                                <p><strong>Important:</strong>You're reactivating a customer's canceled e-commerce plan. If the active role limit is 5 or fewer, advise the customer to reactivate the plan themselves to set up their payment method. If it exceeds 5, you can proceed to set it up from the admin side.</p>
                                <p>Once payment is processed, the plan's start and renewal dates will be automatically set.</p>
                            </div>
                        </Col>
                    </BootstrapRowColCombo>}


                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handleActiveRoleLimitChanged}
                            value={state.activeRoleLimit}
                            disabled={!state.inCompanyPlanEditMode}
                            validationState={state.validation.activeRoleLimitValidState}
                            label="Active Role Limit" />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            disabled={!state.inCompanyPlanEditMode}
                            options={typeOfPlans}
                            value={state.typeOfPlan}
                            onChange={handleTypeOfPlanChanged}
                            label="Default Role Type" />
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDateTimePicker
                            disabled={company.is_from_drive_through || !state.inCompanyPlanEditMode}
                            label={state.isFreeTrial ? "Trial Start Date" : "Plan Start Date"}
                            value={isReactivating ? null : state.planStartDate}
                            onChange={handlePlanStartDateChanged}
                            placeholderText={isReactivating ? 'Will be set automatically' : null}
                        />
                    </Col>
                    <Col xs={6}>
                        {company.is_from_drive_through ?
                            <NewAdvanceDateTimePicker
                                disabled={true}
                                value={planRenewalDate()}
                                onChange={handlePlanEndDateChanged}
                                placeholderText={isReactivating ? 'Will be set automatically' : null}
                                label={
                                    state.cancelationDate ? "Plan End Date" :
                                        <div className={classes.LabelWithPopover}>
                                            <span>{state.isFreeTrial ? "Trial End Date" : "Plan Renewal Date"}</span>
                                            {!state.isFreeTrial && (
                                                <PopoverHandler overlay={planRenewalDatePopOver} />
                                            )}
                                        </div>
                                }
                            /> :
                            <NewAdvanceDateTimePicker
                                disabled={!state.inCompanyPlanEditMode}
                                value={state.planEndDate}
                                onChange={handlePlanEndDateChanged}
                                label={'Plan End Date'}
                            />}
                    </Col>
                </BootstrapRowColCombo>
                {company.is_from_drive_through && !state.isFreeTrial ?
                    <BootstrapRowColCombo>
                        <Col xs={6}>
                            <NewAdvanceDropdown
                                disabled={!isReactivating}
                                options={billingCycleDropdown}
                                value={state.billingCycle}
                                onChange={handleBillingCycleChanged}
                                label={
                                    <div className={classes.LabelWithPopover}>
                                        <span style={{ fontSize: 16 }}>Subscription Type</span>
                                        <PopoverHandler overlay={subscriptionTypePopOver} />
                                    </div>
                                }
                            />
                        </Col>
                        <Col xs={6}>
                            <NewAdvanceDropdown
                                disabled={true}
                                options={hearAboutUsOptions}
                                value={state.hearAboutUs}
                                onChange={handleHearAboutUsChanged}
                                label={<span style={{ fontSize: 16 }}>How did they hear about Rolebot?</span>} />
                            </Col>
                        </BootstrapRowColCombo> : null}

                {company.customer_pricing_plan && !state.isFreeTrial && <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handlePricePerMonthChanged}
                            value={state.pricePerMonth}
                            disabled={!isReactivating}
                            type="number"
                            step="0.01"
                            label="Price per month (USD)" />
                    </Col>
                </BootstrapRowColCombo>}

                {(state.cancelationDate && !state.requiresReactivation) && (
                    <BootstrapRowColCombo>
                        <Col xs={12} className={classes.CancellationInfoBox}>
                            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className={classes.IconExclamationTriangle} />
                            <span> This customer canceled their subscription on {moment.utc(company.customer_pricing_plan.cancelation_date + 'T12:00:00Z').local().format('MMMM D, YYYY')}. </span>
                            <strong>Plan active until {state.planEndDate ? moment.utc(company.plan_end_date).local().format('MMMM D, YYYY') : 'N/A'}.</strong>
                        </Col>
                    </BootstrapRowColCombo>
                )}

                {!state.inCompanyPlanEditMode && !state.inCreditPricingEditMode && !state.inPaymentMethodEditMode && (
                    <BootstrapRowColCombo style={{ marginTop: '40px' }}>
                        <Col xs={12}>
                            <Button
                                bsStyle="success"
                                onClick={() => handleUpgradeCompany()}
                            >
                                Upgrade company to Pro plan
                            </Button>
                        </Col>
                    </BootstrapRowColCombo>
                )}

                <div className={classes.SectionDivider} />


            {/* Credit pricing section */}
            <FormEditButtonArea
                    editDisabled={state.loading}
                    showEdit={true}
                    inEditMode={state.inCreditPricingEditMode}
                    onEditClicked={handleCreditPricingEditEnabled}
                    onCancelClicked={handleEditCanceled}
                    onSaveClicked={handleSubmitCreditPlanChanges}
                    saveDisabled={state.submitDisabled}
                    editBtnText="Edit"
                    title="Credit Pricing"
                />

            <BootstrapRowColCombo>
                <Col xs={6}>
                    <NewAdvanceSliderButton
                        value={state.isCreditPricing}
                        onChange={handleIsCreditPricingChanged}
                        label="Enable Credit Pricing"
                        disabled={!state.inCreditPricingEditMode}
                        style={{ flexDirection: 'column' }}
                    />
                </Col>
            </BootstrapRowColCombo>
            
            {/* Current Plan Section */}
            {(state.isCreditPricing && company.is_credit_pricing) && (
                <div className={classes.borderSoft}>
                    <BootstrapRowColCombo>
                        <Col xs={12}>
                            <div className={classes.currentPlanContainer}>
                                {/* <h4 style={{ fontWeight: 800, color: '#242424' }}>
                                    Current Plan
                                </h4> */}
                                {state.customerCreditPlan && (
                                    <div className={classes.activeLabel}>
                                        Active
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col xs={12}>
                        {state.customerCreditPlan ? (
                            <PlanCard
                                costPerCandidateInDollars={centsToDollars(state.customerCreditPlan.credit_plan_info.cost_per_candidate) }
                                candidateVolume={state.customerCreditPlan.credit_plan_info.candidate_volume}
                                billingRenewalDate={state.customerCreditPlan.credit_plan_info.next_billing_date}
                                planName={state.customerCreditPlan.credit_plan_info.name ?? 'Freemium'}
                            />
                        ) : (
                            <div>This customer has no <strong>active</strong> credit plan.</div>
                        )}
                        </Col>
                    </BootstrapRowColCombo>
                </div>
            )}

            {(
            state.isCreditPricing && 
            company.is_credit_pricing && 
            state.addCreditPlanEditMode) && (
                <>
                    <BootstrapRowColCombo style={{ marginTop: '20px' }}>
                    { state.creditPlanInputs.id ? (
                        <Col xs={12} style={{ marginTop: '20px' }}>
                            <h4 className="text-danger fs-5 fw-bold" >Editing Credit Plan: {state.creditPlanInputs.name}</h4>
                        </Col>
                        ) : (
                            <Col xs={12} style={{ marginTop: '20px' }}>
                                <h4 className="text-danger fs-5 fw-bold" >Create New Custom Plan</h4>
                                <p className="fs-6" style={{ color: "#6c757d" }}>Custom plans are created for this customer only.</p>
                            </Col>
                        )}
                        <Col xs={6}>
                            <NewAdvanceTextInput
                                // loading={state.loading}
                                onChange={handlePlanNameChanged}
                                value={state.creditPlanInputs.name}
                                disabled={!state.addCreditPlanEditMode}
                                validationState={state.validation.planNameValidState}
                                label="Plan Name"
                            />
                            <NewAdvanceTextInput
                                // loading={state.loading}
                                onChange={handleCandidateVolumeChanged}
                                value={state.creditPlanInputs.candidate_volume}
                                disabled={!state.addCreditPlanEditMode}
                                validationState={state.validation.candidateVolumeValidState}
                                label="Candidate Volume" />
                        </Col>
                        <Col xs={6}>
                            <NewAdvanceTextInput
                                // loading={state.loading}
                                onChange={handleCostPerCandidateChanged}
                                value={state.creditPlanInputs.cost_per_candidate}
                                disabled={!state.addCreditPlanEditMode}
                                validationState={state.validation.costPerCandidateValidState}
                                label="Cost per Candidate ($)"
                                placeholder="0.00"
                            />
                        </Col>

                        <Col xs={6}>
                            <NewAdvanceDropdown
                                options={[
                                    { id: 'monthly', name: 'Monthly' },
                                    { id: 'yearly', name: 'Yearly' }
                                ]}
                                value={state.creditPlanInputs.billing_type}
                                onChange={handleBillingTypeChanged}
                                label="Billing Type"
                                disabled={!state.addCreditPlanEditMode}
                            />
                        </Col>
                        <Col xs={12}>
                            <Button onClick={handleSubmitCreditPlanChanges}>{state.loading ? 'Saving...' : 'Save'}</Button>
                        </Col>
                    </BootstrapRowColCombo>
                </>
            )}


            {
                (state.inCreditPricingEditMode &&
                    //state.availableCreditPlans.length > 0 &&
                    state.isCreditPricing &&
                    company.is_credit_pricing
                ) && (
                    <>
                        {/* Custom Plans Section */}
                        <div className={classes.borderSoft} style={{ marginTop: '60px' }}>
                            <div className={`${classes.sectionHeader}`} onClick={toggleCustomPlans}>
                                <div className={classes.sectionHeaderContent}>
                                    <h4 className={classes.sectionTitle}>Custom Plans</h4>
                                </div>
                                <div className={classes.sectionHeaderContent}>
                                {!state.addCreditPlanEditMode  && (
                                    <BootstrapRowColCombo>
                                        <Col xs={12} style={{ textAlign: 'right' }}>
                                            <div className={classes.addPlanButton} onClick={handleAddPlanEditEnabled}>Add Plan!</div>
                                        </Col>
                                    </BootstrapRowColCombo>
                                )}
                                {state.isCustomPlansCollapsed ? <FaChevronDown className={classes.iconMargin} /> : <FaChevronUp className={classes.iconMargin} />}
                                </div>
                            </div>



                            <Collapse in={!state.isCustomPlansCollapsed}>


                                {customPlans.length > 0 ? (
                                    <div>
                                        <BootstrapRowColCombo className={classes.noCustomPlans}>
                                            {customPlans.map(plan => (
                                                <Col
                                                    xs={12} 
                                                    key={`plan-card-${plan.id}`}
                                                    className={`${plan.id === state.creditPlanInputs.id ? classes.selectedPlan : ''}`}
                                                >
                                                    <PlanCard
                                                        costPerCandidateInDollars={centsToDollars(plan.cost_per_candidate)}
                                                        candidateVolume={plan.candidate_volume}
                                                        planName={plan.name}
                                                        planId={plan.id}
                                                        showDelete={true}
                                                        onDeleteClicked={handleDeleteCreditPlan}
                                                        onEditClicked={handleSelectCreditPlan}
                                                        showEdit={true}
                                                    />
                                                </Col>
                                            ))}
                                        </BootstrapRowColCombo>
                                    </div>
                                ) : (
                                    <div>This customer has no <strong>custom</strong> plans.</div>
                                )}
                            </Collapse>
                        </div>

                        {/* Default Plans Section */}
                        <div className={classes.borderSoft}>
                            <div className={`${classes.sectionHeader}`} onClick={toggleDefaultPlans}>
                                <h4 className={classes.sectionTitle}>Default Plans</h4>
                                {state.isDefaultPlansCollapsed ? <FaChevronDown className={classes.iconMargin} /> : <FaChevronUp className={classes.iconMargin} />}
                            </div>

                            <Collapse in={!state.isDefaultPlansCollapsed}>
                                <div>
                                <p className="fs-6 ms-5" style={{ color: "#6c757d" }}>(Default plans are edited in Nova.)</p>
                                    <BootstrapRowColCombo>
                                        {defaultPlans.map(plan => (
                                            <Col
                                                xs={12}
                                                key={`plan-card-${plan.id}`}
                                            >
                                                <PlanCard
                                                    costPerCandidateInDollars={centsToDollars(plan.cost_per_candidate)}
                                                    candidateVolume={plan.candidate_volume}
                                                    planName={plan.name}
                                                    planId={plan.id}
                                                    showDelete={false}
                                                    showEdit={false}
                                                />
                                            </Col>
                                        ))}
                                    </BootstrapRowColCombo>
                                </div>
                            </Collapse>
                        </div>
                    </>
                )
            }

            <div className={classes.SectionDivider} />

            {/* Payment method section */}
                    <>
                        <FormEditButtonArea
                            editDisabled={state.loading}
                            showEdit={true}
                            inEditMode={state.inPaymentMethodEditMode}
                            onEditClicked={handlePaymentMethodEditEnabled}
                            onCancelClicked={handleEditCanceled}
                            onSaveClicked={handleSubmitChanges}
                            saveDisabled={state.submitDisabled}
                            editBtnText="Edit"
                            title="Payment Method"
                        />

                        <BootstrapRowColCombo>
                            <Col xs={6}>
                                <NewAdvanceDropdown
                                    label="Payment Method"
                                    disabled={!state.inPaymentMethodEditMode}
                                    options={paymentMethodsDropdown}
                                    value={state.defaultPaymentMethod}
                                    onChange={handlePaymentMethodChanged}
                                    placeholder="-- SELECT --" />
                            </Col>
                        </BootstrapRowColCombo>
                    </>
                {state.defaultPaymentMethod === 'cc' && !state.isFreeTrial && (<>

                <BootstrapRowColCombo style={{ marginTop: '40px' }}>
                    <Col xs={12} lg={4}>
                        <NewAdvanceSliderButton
                            value={state.forceCollectCard}
                            onChange={handleForceCollectCardChanged} label={(<>Collect Card
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="right"
                                    overlay={(
                                        <Popover id="popover-positioned-right" title="Force Collect Card">
                                            Checking this will force the user to submit a card. Can only be used if <strong style={{ color: "red" }}>ALL</strong> the users added to the role are
                                            missing card data.
                                        </Popover>
                                    )}>
                                    <BsFillQuestionCircleFill className={classes.HelpIcon} />
                                </OverlayTrigger>
                            </>)} disabled={!state.inPaymentMethodEditMode} />
                    </Col>
                </BootstrapRowColCombo>
                    {state.card_info && !state.isFreeTrial &&
                        <BootstrapRowColCombo style={{ backgroundColor: "#e5e5e5", backgroundClip: "content-box" }}>
                            <Col lg={12}>
                                <h5 style={{ fontWeight: "bold" }}>Name: <span style={{ marginLeft: 10 }}>{state.card_info ? state.card_info.cardholder_name : ''}</span></h5>
                            </Col>
                            <Col lg={6}>
                                <h4><FontAwesomeIcon icon={faCreditCard} /><span style={{ marginLeft: 10 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 10 }}>
                                    {state.card_info ? state.card_info.card_last_four ? state.card_info.card_last_four.toString().padStart(4, '0') : '' : ''}</span></h4>
                            </Col>
                            <Col lg={6} style={{ textAlign: "end" }}>
                                <h5><span style={{ fontWeight: "bold" }}>Stripe ID: </span>{state.card_info ? state.card_info.stripe_id : ''}</h5>
                            </Col>
                        </BootstrapRowColCombo>
                    }</>)}

                {state.card_info && <br />}
            </Fragment>
            <ReactivatePlanModal show={showReactivatePlanModal} customer={company} state={state} onHide={() => setShowReactivatePlanModal(false)} />
        </>
    );
};

export default React.memo(CompanyPlanForm);