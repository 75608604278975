import {
    getCompanies,
    postCompanies,
    patchCompany,
    getCompanyUsers,
    deleteUserFromCompany,
    postUserToCompany,
    getUser,
    patchCompanyUser,
    postRole,
    deleteUserPaymentMethod,
    patchUserPaymentMethod, getCompanyContactOutPlans,
    getCompanyCardInfo, getCompanyRoles,
    reactivatePricingPlan
} from '../../utils/services/turboPlus2RestCalls';
import {
    GET_COMPANY_USERS_REQUEST,
    GET_COMPANY_USERS_SUCCESS,
    GET_COMPANY_ROLES_REQUEST,
    GET_COMPANY_ROLES_SUCCESS,
    DELETE_COMPANY_USER_SUCCESS,
    POST_COMPANY_USER_SUCCESS,
    COMPANY_LOADING,
    POSTED_COMPANY_ADDED,
    PATCHED_COMPANY_RETURNED,
    GET_COMPANIES_SUCCESS,
    GET_DEFAULT_CREDIT_PLANS_SUCCESS,
    PATCH_COMPANY_USER_SUCCESS,
    POST_COMPANY_ROLE_SUCCESS,
    DELETE_COMPANY_USER_PAYMENT_REQUEST,
    DELETE_COMPANY_USER_PAYMENT_SUCCESS,
    PATCH_COMPANY_USER_PAYMENT_REQUEST,
    PATCH_COMPANY_USER_PAYMENT_SUCCESS,
    GET_COMPANY_USER_REQUEST,
    GET_COMPANY_USER_SUCCESS,
    PATCH_COMPANY_REQUEST,
    POST_COMPANY_ROLE_REQUEST,
    GET_CONTACT_OUT_PLANS_REQUEST,
    GET_CONTACT_OUT_PLANS_SUCCESS,
    GET_COMPANY_CARD_INFO_REQUEST,
    GET_COMPANY_CARD_INFO_SUCCESS,
    GET_COMPANY_INDUSTRY_REQUEST,
    GET_COMPANY_INDUSTRY_SUCCESS
} from "./actionsTypes";
import { getRoleRequestsRequest } from "./roleRequests";
import _ from "lodash";
import { showToastError, showToastSuccess } from "../../utils/toastMessages";

export const getCompaniesList = (params) => {
    return (dispatch) => {
        dispatch(companyLoading());

        getCompanies(params)
            .then(({ customers, default_credit_plans }) => {
                dispatch(companiesReturned(customers));
                dispatch(defaultCreditPlansReturned(default_credit_plans));
            })
            .catch(e => console.log(e));
    };
}

export const postNewCompany = (params) => {
    return (dispatch) => {
        dispatch(companyLoading());

        postCompanies(params)
            .then(response => {
                dispatch(postedCompanyReturned(response.customer));
            })
            .catch(e => console.log(e));
    }
};

export const patchCompanyItem = (companyId, params, extraData = {}) => {
    return (dispatch) => {
        dispatch({ type: PATCH_COMPANY_REQUEST });
        patchCompany(companyId, params)
            .then(({ customer: company }) => {
                dispatch({
                    type: PATCHED_COMPANY_RETURNED, payload: {
                        ...company, card_info: extraData.card_info ? extraData.card_info : null,
                        contactOutCreditsUsed: extraData.contactOutCreditsUsed ? extraData.contactOutCreditsUsed : null,
                        rocketReachCreditsUsed: extraData.rocketReachCreditsUsed ? extraData.rocketReachCreditsUsed : null
                    }
                });
                showToastSuccess('Company updated successfully');
                
            })
            .catch(e => {
                console.error('Error updating company:', e);
                showToastError('Error updating company');
            });
    }
};

const companyLoading = () => {
    return {
        type: COMPANY_LOADING
    }
};

export const postedCompanyReturned = (customer) => {
    return {
        type: POSTED_COMPANY_ADDED,
        payload: customer
    }
};

export const companiesReturned = (data) => {
    return {
        type: GET_COMPANIES_SUCCESS,
        payload: data
    }
}

export const defaultCreditPlansReturned = (data) => {
    return {
        type: GET_DEFAULT_CREDIT_PLANS_SUCCESS,
        payload: data
    }
}
export const getCompanyUsersRequest = (companyId) => {
    return (dispatch) => {
        dispatch({ type: GET_COMPANY_USERS_REQUEST })
        getCompanyUsers(companyId)
            .then(response => {
                dispatch(getCompanyUsersSuccessAction(companyId, response.users));
            })
            .catch(e => console.log(e));
    }
}

export const getCompanyUserRequest = (companyId, userId, params = {}) => {
    return (dispatch) => {
        dispatch({ type: GET_COMPANY_USER_REQUEST })
        getUser(userId, params)
            .then(({ user }) => {
                dispatch({ type: GET_COMPANY_USER_SUCCESS, payload: { companyId, user } })
            })
            .catch(e => console.log(e));
    }
}

const getCompanyUsersSuccessAction = (companyId, users) => {
    return { type: GET_COMPANY_USERS_SUCCESS, payload: { companyId, users } };
}

export const getCompanyRolesRequest = (companyId) => {
    return (dispatch) => {
        dispatch({ type: GET_COMPANY_ROLES_REQUEST })
        getCompanyRoles(companyId)
            .then(response => {
                dispatch(getCompanyRolesSuccessAction(companyId, response.roles));
            })
            .catch(e => console.log(e));
    }
}

const getCompanyRolesSuccessAction = (companyId, roles) => {
    return { type: GET_COMPANY_ROLES_SUCCESS, payload: { companyId, roles } };
}

export const removeUserFromCompanyRequest = (companyId, userId) => {
    return dispatch => {
        deleteUserFromCompany(companyId, userId)
            .then(() => {
                dispatch(removeUserFromCompanyAction(companyId, userId));
            })
            .catch(e => console.log(e));
    }
}

const removeUserFromCompanyAction = (companyId, userId) => {
    return { type: DELETE_COMPANY_USER_SUCCESS, payload: { companyId, userId } };
}

export const postUserToCompanyRequest = (companyId, params) => {
    return dispatch => {
        postUserToCompany(companyId, params)
            .then(({ user }) => {
                dispatch(postUserSuccessAction(companyId, user));
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                showToastError(message, errors);
            });
    }
}

const postUserSuccessAction = (companyId, user) => {
    return { type: POST_COMPANY_USER_SUCCESS, payload: { companyId, user } };
}

export const patchCompanyUsersRequest = (companyId, userId, payload) => {
    return dispatch => {
        patchCompanyUser(companyId, userId, payload)
            .then(response => {
                dispatch(patchCompanyUserSuccessAction(companyId, response.user));
                showToastSuccess("User updated correctly")
            })
            .catch(e => {
                showToastError(e.message)
                console.log(e)
            });
    }
}

export const patchCompanyUserSuccessAction = (companyId, user) => {
    return { type: PATCH_COMPANY_USER_SUCCESS, payload: { companyId, user } };
}

export const postRoleToCompanyRequest = (companyId, payload) => {
    return dispatch => {
        dispatch({ type: POST_COMPANY_ROLE_REQUEST })
        postRole(companyId, payload)
            .then(({ role }) => {
                dispatch({ type: POST_COMPANY_ROLE_SUCCESS, payload: { companyId, role } });
                // if a role is created from the role request section we need to tell rolerequests to reload
                // when role is created.
                if (payload.hasOwnProperty('role_request_id')) {
                    dispatch(getRoleRequestsRequest());
                }
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                showToastError(message, errors);
            });
    }
}

export const deleteCompanyUserPaymentMethodRequest = (companyId, userId, payload) => {
    return dispatch => {
        dispatch({ type: DELETE_COMPANY_USER_PAYMENT_REQUEST })
        deleteUserPaymentMethod(userId, payload)
            .then(({ user }) => {
                dispatch({ type: DELETE_COMPANY_USER_PAYMENT_SUCCESS, payload: { companyId, user } });
            })
            .catch(e => console.log(e))
    }
}

export const patchCompanyUserPaymentMethodRequest = (companyId, userId, paymentMethodId, payload) => {
    return dispatch => {
        dispatch({ type: PATCH_COMPANY_USER_PAYMENT_REQUEST })
        patchUserPaymentMethod(userId, paymentMethodId, payload)
            .then(({ user }) => {
                dispatch({ type: PATCH_COMPANY_USER_PAYMENT_SUCCESS, payload: { companyId, user } });
            })
            .catch(e => console.log(e))
    }
}

export const getCompanyContactOutPlansRequest = (companyId) => {
    return dispatch => {
        dispatch({ type: GET_CONTACT_OUT_PLANS_REQUEST });
        getCompanyContactOutPlans(companyId)
            .then(({ contact_plans, rocket_reach_plans }) => {
                dispatch({ type: GET_CONTACT_OUT_PLANS_SUCCESS, payload: { companyId, contact_plans, rocket_reach_plans } })
            })
            .catch(e => console.log(e));
    }
}


export const getCompanyCardInfoRequest = (companyId) => {
    return dispatch => {
        dispatch({ type: GET_COMPANY_CARD_INFO_REQUEST });
        getCompanyCardInfo(companyId)
            .then((res) => {
                dispatch({ type: GET_COMPANY_CARD_INFO_SUCCESS, payload: { companyId, card_info: res["company-card"] } })
            })
            .catch(e => console.log(e));
    }
}

export const postCompanyReactivationRequest = (companyId, payload) => {
    return dispatch => {
        dispatch({ type: PATCH_COMPANY_REQUEST });
        reactivatePricingPlan(companyId, payload)
            .then(({ customer: company }) => {
                dispatch({
                    type: PATCHED_COMPANY_RETURNED, payload: {
                        ...company, card_info: null,
                        contactOutCreditsUsed: null,
                        rocketReachCreditsUsed: null
                    }
                });
            })
            .catch(e => console.log(e));
    }
}

