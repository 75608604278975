import axios from '../../utils/axiosAuthInstance';
import version from '../turbot2Version';

const ApiUrl = process.env.REACT_APP_TURBO2_API;

axios.defaults.withCredentials = true;

const getConfig = () => {
    return {
        "headers": {
            "Content-Type": "application/json",
            "X-App-Version": version,
            "Accept": "application/json",
            "Access-Control-Allow-Credentials": true,
        }
    }
};

export const getCsrfCookie = () => axios.get(`${ApiUrl}/sanctum/csrf-cookie`, getConfig());

export const login = (payload) => axios.post(`${ApiUrl}/admin/login`, payload, getConfig());

export const getOutcomes = () => axios.get(`${ApiUrl}/api/outcomes`, getConfig());

export const getFollowUps = () => axios.get(`${ApiUrl}/api/follow-ups`, getConfig());

export const getRoles = (params) => axios.get(`${ApiUrl}/api/roles`, { ...getConfig(), ...params });

export const getRoleById = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postRole = (companyId, payload = {}) => {
    return axios.post(`${ApiUrl}/api/customers/${companyId}/roles`, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchRole = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getRoleUsers = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/users`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getRoleTalent = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postRoleTalent = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getUserByEmail = (email) => {
    const url = `${ApiUrl}/api/user/find-by-email?email=${email}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getUsers = (params) => {
    const url = `${ApiUrl}/api/users`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getUser = (userId, params) => {
    const url = `${ApiUrl}/api/users/${userId}`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const postUserToCompany = (companyId, params) => {
    const url = `${ApiUrl}/api/customers/${companyId}/users`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });

}

export const addUserToRolebot = (payLoad) => {
    const url = `${ApiUrl}/api/user`;

    return axios.post(url, payLoad, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const addUserCard = (userId, payLoad) => {
    const url = `${ApiUrl}/api/user/${userId}/add-card`;

    return axios.post(url, payLoad, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchCompanyUser = (companyId, userId, payLoad) => {
    const url = `${ApiUrl}/api/customers/${companyId}/users/${userId}`;

    return axios.patch(url, payLoad, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const deleteUserFromCompany = (companyId, userId) => {
    const url = `${ApiUrl}/api/customers/${companyId}/users/${userId}`;
    return axios.delete(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const patchTalent = (roleId, talentId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent/${talentId}`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const deleteTalent = (talentId, roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent/${talentId}`;

    return axios.delete(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchRoleInfo = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/info`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const addUserToRole = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/users`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const addUserToRoles = ( payload = {} ) => {
    const url = `${ApiUrl}/api/roles/user`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postProPlan = (payload = {}) => {
    const url = `${ApiUrl}/api/pricing-plan/pro-plan`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getUserRoles = ( userId, payload = {} ) => {
    const url = `${ApiUrl}/api/user/${userId}/roles`;

    return axios.get(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const removeUserFromRole = (roleId, userId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/users/${userId}`;

    return axios.delete(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const setUserAsInterviewer = (roleId, userId, payload) => {
    const url = `${ApiUrl}/api/roles/${roleId}/users/${userId}`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchRoleProfile = (roleId, profileId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/profiles/${profileId}`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const sendTalentAdminReminders = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/send-talent-reminders`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const sendInfoAdminReminders = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/send-info-reminders`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const deleteRole = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}`;

    return axios.delete(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getAdmins = () => {
    const url = `${ApiUrl}/api/admins`;
    return axios.get(url, getConfig());
};

export const getTalentsLocation = (params) => {
    const url = `${ApiUrl}/api/talent/locations`;
    
    return axios.get(url, {...getConfig(), ...params})
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const postAdmin = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/admin`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postTalentAdmin = (talentId, payload = {}) => {
    const url = `${ApiUrl}/api/talent/${talentId}/admin`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getRoleBasicInfo = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/basic-info`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getRoleHistory = (roleId, payload = {}) => {
    const url = `${ApiUrl}/api/roles/${roleId}/history`;

    return axios.get(url, { ...getConfig(), params: payload })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const deleteFollowUp = (talentId, followUpPivotId) => {
    const url = `${ApiUrl}/api/talent/${talentId}/follow-up/${followUpPivotId}`;

    return axios.delete(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const cancelInterview = (interviewId) => {
    const url = `${ApiUrl}/api/interviews/${interviewId}/cancel`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const refundInterviews = (talentId, roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent/${talentId}/refund-interviews`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getProfiles = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/profiles`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getTalentProspects = (roleId, params) => {
    const url = `${ApiUrl}/api/talent-prospects/${roleId}`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getAutoSurfacedTalentProspects = (roleId, params) => {
    const url = `${ApiUrl}/api/auto-surfaced-prospects/${roleId}`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getTalentProspectsStats = (roleId) => {
    const url = `${ApiUrl}/api/talent-prospects/${roleId}/stats`;

    return axios.get(url, { ...getConfig() })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postTalentProspects = (roleId, params) => {
    const url = `${ApiUrl}/api/talent-prospects/${roleId}`;
    params.admin_side = 1;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const patchTalentForReview = (talentProspectId, roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/talent-prospects/${talentProspectId}`;

    return axios.patch(url, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const flagTalentAsInvalidLink = (talentId) => {
    const url = `${ApiUrl}/api/talent-prospects/${talentId}/flag-invalid`;

    return axios.patch(url, {}, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getCompanies = (params) => {
    return axios.get(`${ApiUrl}/api/customers`, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postCompanies = (params) => {
    return axios.post(`${ApiUrl}/api/customers`, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchCompany = (companyId, params) => {
    return axios.patch(`${ApiUrl}/api/customers/${companyId}`, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const deleteCreditPlan = async (companyId, creditPlanId) => {
    const url = `${ApiUrl}/api/customer-credit-pricing-plans/${companyId}`;
    
    return axios.delete(url, { 
        ...getConfig(), 
        data: { credit_plan_id: creditPlanId }
    })
    .then(response => {
        return response.data;
    })
    .catch(err => {
        throw err.response.data;
    });
}

export const getCompanyUsers = (companyId) => {
    return axios.get(`${ApiUrl}/api/customers/${companyId}/users`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getCompanyRoles = (companyId) => {
    return axios.get(`${ApiUrl}/api/customers/${companyId}/roles`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getPaymentIntent = (userId) => {
    return axios.get(`${ApiUrl}/api/user/${userId}/payment/get-intent`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const addCardToUser = (userId, params) => {
    return axios.post(`${ApiUrl}/api/user/${userId}/card`, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const deleteUserPaymentMethod = (userId, paymentMethodId) => {
    return axios.delete(`${ApiUrl}/api/users/${userId}/payment-method/${paymentMethodId}`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const patchUserPaymentMethod = (userId, paymentMethodId, params) => {
    return axios.patch(`${ApiUrl}/api/users/${userId}/payment-method/${paymentMethodId}`, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getRoleRequests = () => axios.get(`${ApiUrl}/api/role-requests`, getConfig());

export const getCompanyMetrics = (companyId, params) => {
    return axios.get(`${ApiUrl}/api/customers/${companyId}/metrics`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const patchRoleRequests = (talentRequestId, params) =>
    axios.patch(`${ApiUrl}/api/role-requests/${talentRequestId}`, params, getConfig());

export const deleteRoleRequests = (talentRequestId, params) =>
    axios.delete(`${ApiUrl}/api/role-requests/${talentRequestId}`, getConfig());

export const getCompanyContactOutPlans = (companyId) => {
    return axios.get(`${ApiUrl}/api/customers/${companyId}/contact-out-plans`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getTalentContactOutInfo = (talentId) => {
    return axios.get(`${ApiUrl}/api/talent/${talentId}/contact-out-info`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getTalentRocketReachInfo = (talentId) => {
    return axios.get(`${ApiUrl}/api/talent/${talentId}/rocket-reach-info`, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}


/**
 * Billing
 */

export const getCharges = (payload) => {
    const params = Object.entries(payload).map(
        ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    ).join("&");

    let url = `${ApiUrl}/api/charges?${params}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const patchCharges = (chargeId, payload = {}) => {
    const url = `${ApiUrl}/api/charges/${chargeId}`;

    return axios.patch(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};


export const getCompaniesExport = (params) => {
    const url = `${ApiUrl}/api/customers/export`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getCompanyCardInfo = (companyId) => {
    const url = `${ApiUrl}/api/customers/${companyId}/card-info`;

    return axios.get(url, { ...getConfig() })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

/**
 * Apollo calls
 */

export const getApolloSequences = (params) => {
    const url = `${ApiUrl}/api/apollo/search-sequence`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const postRoleToApolloSequence = (roleId, params) => {
    const url = `${ApiUrl}/api/apollo/role/${roleId}/sequence`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const deleteRoleToApolloSequence = (roleId, params) => {
    const url = `${ApiUrl}/api/apollo/role/${roleId}/sequence`;

    return axios.delete(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const reSyncApolloData = (params) => {
    const url = `${ApiUrl}/api/apollo/sync-talent`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const clearApolloNotifications = (roleId) => {
    const url = `${ApiUrl}/api/apollo/role/${roleId}/reset-notifications`;

    return axios.post(url, { ...getConfig() })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

/**
 * monday.com calls
 */
export const getMondayBoards = () => {
    const url = `${ApiUrl}/api/monday/boards/get-all`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getMondayGroups = (bordId, roleId) => {
    const url = `${ApiUrl}/api/monday/boards/${bordId}/groups/${roleId}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const reSyncMondayData = () => {
    const url = `${ApiUrl}/api/monday/sync`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getMatchingTalentCount = (payload) => {
    const params = Object.entries(payload).map(
        ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    ).join("&");

    const url = `${ApiUrl}/api/talent/matching-talent-list?${params}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getMatchingTalent = (payload = {}) => {
    const url = `${ApiUrl}/api/talent/matching-talent`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getCardMessages = () => {
    const url = `${ApiUrl}/api/card-messages`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
};

export const getRoleSurfaceDates = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/surface-dates`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });
}

export const getRoleLastReviewedDate = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/last-reviewed-date`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw err.response.data;
        });

}

export const autoScheduleTalent = (roleId, talentId) => {
    const url = `${ApiUrl}/api/cronofy/roles/${roleId}/talent/${talentId}/real-time-scheduling`;

    return axios.post(url, undefined, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

/**
 * Job titles/Skills search calls + recommendations from AI
 */

export const getJobTitlesByRoleSearch = (params) => {
    const url = `${ApiUrl}/api/job-titles`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const getSkillsByRoleSearch = (params) => {
    const url = `${ApiUrl}/api/skills`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const getKeywordByRoleSearch = (params) => {
    const url = `${ApiUrl}/api/keywords`;

    return axios.get(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const getIdealTalentsForRole = (roleId, skills, locations) => {
    let url = `${ApiUrl}/api/roles/${roleId}/ideal-talent?skills=${skills}`;

    if (locations !== "") {
        url = `${url}&locations=${locations}`
    }

    return axios.get(url, { ...getConfig() })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const postJobTitles = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-job-titles`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const postRoleSkills = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-skills`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const postKeywords = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-keywords`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const postUserCreatedSkills = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-user-created-skills`;

    return axios.post(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const patchJobTitle = (roleId, jobTitleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-job-titles/${jobTitleId}`;

    return axios.patch(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const patchSkill = (roleId, skillId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-skills/${skillId}`;

    return axios.patch(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const patchKeyword = (roleId, keywordId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-keywords/${keywordId}`;

    return axios.patch(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const patchUserCreatedSkill = (roleId, userCreatedSkillId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-user-created-skills/${userCreatedSkillId}`;

    return axios.patch(url, params, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const refreshRoleTags = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/refresh-role-tags`;

    return axios.patch(url, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const deleteJobTitle = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-job-titles`;

    return axios.delete(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const deleteSkill = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-skills`;

    return axios.delete(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const deleteKeyword = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-keywords`;

    return axios.delete(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const deleteUserCreatedSkill = (roleId, params) => {
    const url = `${ApiUrl}/api/roles/${roleId}/role-user-created-skills`;

    return axios.delete(url, { ...getConfig(), ...params })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const getTypeOfPlans = () => {
    const url = `${ApiUrl}/api/role-types`
    return axios.get(url, getConfig())
        .then(response => {
            return response.data.roleTypes
        })
        .catch(err => { throw err.response.data });
}

export const getMondayBoardsDropdown = () => {
    const url = `${ApiUrl}/api/monday/boards`
    return axios.get(url, getConfig())
        .then(response => {
            return response.data.boards
        })
        .catch(err => { throw err.response.data });
}

export const getIndustries = (params) => {
    const url = `${ApiUrl}/industries`;

    return axios.get(url, { ...getConfig(), ...params } )
        .then(response => {
            return response.data.data;
        })
        .catch(err => {
            throw err.response.data;
    });
};

export const markTemplateReviewed = (roleId) => {
    const url = `${ApiUrl}/api/roles/${roleId}/email-reviewed`;

    return axios.patch(url, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const reactivatePricingPlan = (customerId, payload) => {
    const url = `${ApiUrl}/api/customers/${customerId}/reactivate-pricing-plan`;

    return axios.post(url, payload, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const removeAutoSourcedTalent = (roleId, talentId) => {
    const url = `${ApiUrl}/api/talent-prospects/${roleId}/${talentId}/remove-auto-surfaced-talent`;

    return axios.post(url, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}

export const triggerAutosurfaced = async (roleId) => {
    const url = `${ApiUrl}/api/roles/trigger-autosurfaced/${roleId}`;

    return axios.post(url, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
};

export const getAvailableCreditPlans = (customerId) => {
    const url = `${ApiUrl}/customer-credit-pricing-plans/${customerId}`;

    return axios.get(url, getConfig())
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
}
