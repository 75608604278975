export const APP_READY = 'APP_READY';

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_NEEDS_PASSWORD_CHANGE = 'AUTH_NEEDS_PASSWORD_CHANGE';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_EMAIL_VERIFICATION_SUCCESS = 'AUTH_EMAIL_VERIFICATION_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_UPDATE_TOKEN = 'AUTH_UPDATE_TOKEN';


export const APP_SETTINGS_UPDATE_MODE = 'APP_SETTINGS_UPDATE_MODE';
export const APP_SETTINGS_UPDATE_CSV_PREFERENCE = 'APP_SETTINGS_UPDATE_CSV_PREFERENCE';
export const APP_SETTINGS_SAVE_CSV_PREFERENCES = 'APP_SETTINGS_SAVE_CSV_PREFERENCES';
export const APP_SETTINGS_RESET_CSV_PREFERENCES = 'APP_SETTINGS_RESET_CSV_PREFERENCES';


export const ROLES_SEARCH_SIDE_DRAWER_OPENED = 'ROLES_SEARCH_SIDE_DRAWER_OPENED';
export const ROLES_SEARCH_SIDE_DRAWER_CLOSED = 'ROLES_SEARCH_SIDE_DRAWER_CLOSED';


export const GLOBAL_LISTS_GET_OUTCOMES_SUCCESS = 'GLOBAL_LISTS_GET_OUTCOMES_SUCCESS';
export const GLOBAL_LISTS_GET_ADMINS_SUCCESS = 'GLOBAL_LISTS_GET_ADMINS_SUCCESS';


export const ROLES_SEARCH_ADMIN_CHANGED = "ROLES_SEARCH_ADMIN_CHANGED";
export const ROLES_SEARCH_TYPE_CHANGED = "ROLES_SEARCH_TYPE_CHANGED";
export const ROLES_SEARCH_VALUE_CHANGED = "ROLES_SEARCH_VALUE_CHANGED";
export const ROLES_SEARCH_VALUE_RESET = "ROLES_SEARCH_VALUE_RESET";
export const ROLES_SEARCH_PAGE_CHANGED = "ROLES_SEARCH_PAGE_CHANGED";

export const ROLE_TYPE_CHANGED = 'ROLE_TYPE_CHANGED';

export const ROLE_ROW_CLICKED = 'ROLE_ROW_CLICKED';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const REFRESH_ROWS = 'REFRESH_ROWS';
export const GOT_ROLE = 'GOT_ROLE';
export const GET_TYPE_OF_PLAN = 'GET_TYPE_OF_PLAN';
export const GET_MONDAY_BOARDS_DROPDOWN = 'GET_MONDAY_BOARDS_DROPDOWN';

export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_DEFAULT_CREDIT_PLANS_SUCCESS = 'GET_DEFAULT_CREDIT_PLANS_SUCCESS';
export const POSTED_COMPANY_ADDED = 'POSTED_COMPANY_ADDED';
export const PATCH_COMPANY_REQUEST = 'PATCH_COMPANY_REQUEST';
export const PATCHED_COMPANY_RETURNED = 'PATCHED_COMPANY_RETURNED';
export const COMPANY_LOADING = 'COMPANY_LOADING';

export const GET_COMPANY_USERS_REQUEST = 'GET_COMPANY_USERS_REQUEST';
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS';

export const GET_COMPANY_ROLES_REQUEST = 'GET_COMPANY_ROLES_REQUEST';
export const GET_COMPANY_ROLES_SUCCESS = 'GET_COMPANY_ROLES_SUCCESS';

export const GET_COMPANY_USER_REQUEST = 'GET_COMPANY_USER_REQUEST';
export const GET_COMPANY_USER_SUCCESS = 'GET_COMPANY_USER_SUCCESS';

export const PATCH_COMPANY_USER_SUCCESS = 'PATCH_COMPANY_USER_SUCCESS';
export const POST_COMPANY_USER_SUCCESS = 'POST_COMPANY_USER_SUCCESS';
export const DELETE_COMPANY_USER_SUCCESS = 'DELETE_COMPANY_USER_SUCCESS';

export const DELETE_COMPANY_USER_PAYMENT_REQUEST = 'DELETE_COMPANY_USER_PAYMENT_REQUEST';
export const DELETE_COMPANY_USER_PAYMENT_SUCCESS = 'DELETE_COMPANY_USER_PAYMENT_SUCCESS';

export const PATCH_COMPANY_USER_PAYMENT_REQUEST = 'PATCH_COMPANY_USER_PAYMENT_REQUEST';
export const PATCH_COMPANY_USER_PAYMENT_SUCCESS = 'PATCH_COMPANY_USER_PAYMENT_SUCCESS';

export const POST_COMPANY_ROLE_REQUEST = 'POST_COMPANY_ROLE_REQUEST';
export const POST_COMPANY_ROLE_SUCCESS = 'POST_COMPANY_ROLE_SUCCESS';

export const LEFT_MENU_COMPANY_CLICKED = 'LEFT_MENU_COMPANY_CLICKED';
export const LEFT_MENU_SUB_MENU_ITEM_CLICKED = 'LEFT_MENU_SUB_MENU_ITEM_CLICKED';
export const LEFT_MENU_ROLES_REQUESTS_CLICKED = 'LEFT_MENU_ROLES_REQUESTS_CLICKED';
export const LEFT_MENU_ADD_COMPANY_CLICKED = 'LEFT_MENU_ADD_COMPANY_CLICKED';
export const LEFT_MENU_ARCHIVED_CLICKED = 'LEFT_MENU_ARCHIVED_CLICKED';

export const LEFT_MENU_MODE_RESET = 'LEFT_MENU_MODE_RESET';

export const GET_ROLE_REQUEST_REQUEST = 'GET_ROLE_REQUEST_REQUEST';
export const GET_ROLE_REQUEST_SUCCESS = 'GET_ROLE_REQUEST_SUCCESS';
export const GET_ROLE_REQUEST_FAIL = 'GET_ROLE_REQUEST_FAIL';

export const PATCH_ROLE_REQUEST_REQUEST = 'PATCH_ROLE_REQUEST_REQUEST';
export const PATCH_ROLE_REQUEST_SUCCESS = 'PATCH_ROLE_REQUEST_SUCCESS';
export const PATCH_ROLE_REQUEST_FAIL = 'PATCH_ROLE_REQUEST_FAIL';

export const DELETE_ROLE_REQUEST_REQUEST = 'DELETE_ROLE_REQUEST_REQUEST';
export const DELETE_ROLE_REQUEST_SUCCESS = 'DELETE_ROLE_REQUEST_SUCCESS';
export const DELETE_ROLE_REQUEST_FAIL = 'DELETE_ROLE_REQUEST_FAIL';


export const POPUP_FILTER_CHANGE_REQUEST = 'POPUP_FILTER_CHANGE_REQUEST';
export const POPUP_FILTERS_RESET = 'POPUP_FILTERS_RESET';

export const GET_ROLE_HISTORY_REQUEST = 'GET_ROLE_HISTORY_REQUEST';
export const GET_ROLE_HISTORY_SUCCESS = 'GET_ROLE_HISTORY_SUCCESS';
export const CHANGE_ROLE_HISTORY_PAGE = 'CHANGE_ROLE_HISTORY_PAGE';

export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const SET_SEARCH_ORDER_TYPE = "SET_SEARCH_TYPE";
export const RESET_SEARCH_ORDER = 'RESET_SEARCH_ORDER';

export const GET_CONTACT_OUT_PLANS_REQUEST = 'GET_CONTACT_OUT_PLANS_REQUEST';
export const GET_CONTACT_OUT_PLANS_SUCCESS = 'GET_CONTACT_OUT_PLANS_SUCCESS';
export const GET_CONTACT_OUT_PLANS_FAIL = 'GET_CONTACT_OUT_PLANS_FAIL';

export const GET_COMPANY_METRICS_REQUEST = 'GET_COMPANY_METRICS_REQUEST';
export const GET_COMPANY_METRICS_SUCCESS = 'GET_COMPANY_METRICS_SUCCESS';
export const GET_ROLE_METRICS_SUCCESS = 'GET_ROLE_METRICS_SUCCESS';
export const GET_COMPANY_METRICS_FAIL = 'GET_COMPANY_METRICS_FAIL';

export const GET_COMPANY_CARD_INFO_REQUEST = 'GET_COMPANY_CARD_INFO_REQUEST';
export const GET_COMPANY_CARD_INFO_SUCCESS = 'GET_COMPANY_CARD_INFO_SUCCESS';

export const GET_COMPANY_INDUSTRY_REQUEST = 'GET_COMPANY_INDUSTRY_REQUEST';
export const GET_COMPANY_INDUSTRY_SUCCESS = 'GET_COMPANY_INDUSTRY_SUCCESS';
