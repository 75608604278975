import * as actionTypes from  '../actions/actionsTypes';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';

const mergeCompanyIntoCompanies = (company, companies) => companies.map(x => x.id === company.id ? company : x);

const sortCompaniesAlphabetically = (companies) => orderBy(companies, [user => user.name.toLowerCase()]);

// const addEmptyUsersArrayToCompaniesArray = (companies) => companies.map(x => ({...x, users: []}));

const addEmptyUserArrayToSingleCompany = (company) => ({...company, users: []});

// const addEmptyUsersDataToUsersArray = (users) => {
//     return users.map(x => addEmptyUserDataToSingleUser(x));
// }

const addEmptyUserDataToSingleUser = (user) => {
    return {
        ...user,
        default_payment_method: user.default_payment_method ? user.default_payment_method : {},
        payment_methods: user.payment_methods ? user.payment_methods : []
    }
}

const initialState = {
    loading: false,
    usersLoading: false,
    companyRolesLoading: false,
    data: []
};

//Takes a user and merges it into and existing user inside a company
function mergeUserIntoCompany(lastState, companyId, user) {
    return lastState.data.map(x => x.id === companyId ?
        {
            ...x,
            users: x.users.map(y => y.id === user.id ?
                {
                    ...y,
                    ...user
                } : y)
        } : x
    );
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case actionTypes.GET_DEFAULT_CREDIT_PLANS_SUCCESS:
            return {
                ...state,
                defaultCreditPlans: action.payload
            }
        case actionTypes.COMPANY_LOADING:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.POSTED_COMPANY_ADDED:
            return {
                ...state,
                loading: false,
                data: sortCompaniesAlphabetically(
                    concat(state.data, addEmptyUserArrayToSingleCompany(action.payload))
                ),
            }
        case actionTypes.PATCHED_COMPANY_RETURNED:
            return {
                ...state,
                data: sortCompaniesAlphabetically(
                    mergeCompanyIntoCompanies(
                        addEmptyUserArrayToSingleCompany(action.payload), state.data
                    )
                )
            }
        case actionTypes.GET_COMPANY_USERS_REQUEST:
            return {
                ...state,
                usersLoading: true,
            }
        case actionTypes.GET_COMPANY_ROLES_REQUEST:
            return {
                ...state,
                companyRolesLoading: true,
            }
        case actionTypes.GET_COMPANY_USER_SUCCESS:
            return {
                ...state,
                data: mergeUserIntoCompany(state, action.payload.companyId, action.payload.user)
            }
        case actionTypes.GET_COMPANY_USERS_SUCCESS:
            return {
                ...state,
                usersLoading: false,
                data: state.data.map(x => {
                    if(x.id === action.payload.companyId){
                        x.users = action.payload.users;
                    }
                    return x;
                })
            }
        case actionTypes.GET_COMPANY_ROLES_SUCCESS:
            return {
                ...state,
                companyRolesLoading: false,
                data: state.data.map(x => {
                    if(x.id === action.payload.companyId){
                        x.roles = action.payload.roles;
                    }
                    return x;
                })
            }
        case actionTypes.DELETE_COMPANY_USER_SUCCESS:
            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.companyId ?
                    {...x, users: x.users.filter(x => x.id !== action.payload.userId)} : x)
            }
        case actionTypes.POST_COMPANY_USER_SUCCESS:
            return {
                ...state,
                data: state.data.map(com => com.id === action.payload.companyId ?
                    {
                        ...com,
                        users: [action.payload.user, ...com.users]
                    } :
                    com
                )
            }
        case actionTypes.PATCH_COMPANY_USER_SUCCESS:
            return {
                ...state,
                data: mergeUserIntoCompany(state, action.payload.companyId, action.payload.user)
            }
        case actionTypes.DELETE_COMPANY_USER_PAYMENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.companyId ?
                    {
                        ...x,
                        users: x.users.map(x => x.id === action.payload.user.id ?
                            addEmptyUserDataToSingleUser(action.payload.user) : x
                        )
                    } : x
                )
            }
        case actionTypes.PATCH_COMPANY_USER_PAYMENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.companyId ?
                    {
                        ...x,
                        users: x.users.map(x => x.id === action.payload.user.id ?
                            addEmptyUserDataToSingleUser(action.payload.user) : x
                        )
                    } : x
                )
            }
        case actionTypes.GET_CONTACT_OUT_PLANS_SUCCESS:

            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.companyId ?
                    {
                        ...x,
                        contact_plans: action.payload.contact_plans,
                        rocket_reach_plans: action.payload.rocket_reach_plans

                    } : x)
            }

        case actionTypes.GET_COMPANY_CARD_INFO_SUCCESS:
            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.companyId ?
                    {
                        ...x,
                        card_info: action.payload.card_info

                    } : x)
            }
        default:
            return state;
    }
}

export default reducer;

