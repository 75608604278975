import React, { Fragment, useReducer, useEffect } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import FormPanel from "../FormPanel/FormPanel";
import AddCompanyHeader from "./Components/AddCompanyHeader/AddCompanyHeader";
import NewTextInput from "../../Ui/Inputs/NewTextInput/NewTextInput";
import { getTypeOfPlan, postNewCompany } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    companyNameValidation,
    companyWebsiteValidation,
    companyNotesValidation,
    isSuccessOrNull,
    isSuccess
} from '../../../utils/validation';
import GreyScrollBoxArea from "../GreyScrollBoxArea/GreyScrollBoxArea";
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewAdvanceDropdown from "../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import { getMondayBoardsDd } from '../../../store/actions/roles';

function reducer(state, action) {
    switch (action.type) {
        case 'nameChanged': {
            const isValid = companyNameValidation(action.payload);
            return {
                ...state,
                name: action.payload,
                validation: {
                    ...state.validation,
                    nameValidState: isValid ? 'success' : 'error',
                    validateName: true
                }
            };
        }
        case 'urlChanged': {
            const isValid = companyWebsiteValidation(action.payload);
            return {
                ...state,
                url: action.payload,
                validation: {
                    ...state.validation,
                    urlValidState: isValid ? 'success' : 'error',
                    validateUrl: true
                }
            };
        }
        case 'notesChanged': {
            const isValid = companyNotesValidation(action.payload);
            return {
                ...state,
                notes: action.payload,
                validation: {
                    ...state.validation,
                    notesValidState: isValid ? 'success' : 'error',
                    validateNotes: true
                }
            };
        }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'accountManagerChanged':
            return {
                ...state,
                adminId: action.payload
            }
        case 'typeOfPlanChanged':
            return {
                ...state,
                typeOfPlan: action.payload
            }
        default:
            throw new Error();
    }
}

const AddCompanySection = () => {
    const reduxDispatch = useDispatch();
    const admins = useSelector(state => state.globalLists.admins);
    const typeOfPlans = useSelector(state => state.roles.typeOfPlans)
    const [state, dispatch] = useReducer(reducer, {
        name: "",
        url: "",
        adminId: '0',
        notes: "",
        submitDisabled: true,
        validation: {
            nameValidState: null,
            urlValidState: null,
            notesValidState: null,
            validateName: false,
            validateUrl: false,
            validateNotes: false,
        },
        typeOfPlan: 1
    });

    useEffect(() => {
        reduxDispatch(getMondayBoardsDd())
        reduxDispatch(getTypeOfPlan())
    }, [])

    useEffect(() => {
        const { nameValidState, urlValidState, notesValidState } = state.validation;
        if (isSuccess(nameValidState) && isSuccessOrNull(urlValidState) && isSuccessOrNull(notesValidState)) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [state.validation]);

    useEffect(() => {
        // Set the turnKeyId to the id of the typeOfPlan that has the name "TurnKey"
        // If no such typeOfPlan is found, set it to 1
        // This is used to set the default typeOfPlan to TurnKey
        if (typeOfPlans) {
            const turnKeyId = typeOfPlans.find(x => x.name.toLowerCase() === "turnkey")?.id || 1;
            dispatch({ type: 'typeOfPlanChanged', payload: turnKeyId });
        }
    }, [typeOfPlans]);

    const handleNameChanged = (e) => {
        dispatch({ type: 'nameChanged', payload: e.currentTarget.value });
    };

    const handleUrlChanged = (e) => {
        dispatch({ type: 'urlChanged', payload: e.currentTarget.value });
    };

    const handleNotesChanged = (e) => {
        dispatch({ type: 'notesChanged', payload: e.currentTarget.value });
    };

    const handleAccountManagerChanged = (e) => {
        dispatch({ type: 'accountManagerChanged', payload: e.currentTarget.value });
    }

    const handleTypeOfPlanChanged = (e) => {
        dispatch({ type: 'typeOfPlanChanged', payload: e.currentTarget.value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let params = {
            name: state.name,
            role_type_id: parseInt(state.typeOfPlan, 10)
        }

        if (state.url !== '') {
            params.url = state.url
        }

        if (state.notes !== '') {
            params.notes = state.notes
        }

        if (state.adminId !== '') {
            params.admin_id = parseInt(state.adminId, 10);
        }

        reduxDispatch(postNewCompany(params));
    }

    return (
        <Fragment>
            <AddCompanyHeader title="Create New Customer">
                Please fill in the following information to create a new ‘Customer’
            </AddCompanyHeader>
            <GreyScrollBoxArea>
                <Form onSubmit={handleFormSubmit} >
                    <BootstrapRowColCombo>
                        <FormPanel title="Company Basics">
                            <BootstrapRowColCombo>
                                <Col xs={12} lg={6}>
                                    <NewTextInput
                                        controlId="form-company-name"
                                        label="Company Name*"
                                        placeholder="Apple"
                                        value={state.name}
                                        onChange={handleNameChanged}
                                        required
                                        validationState={state.validation.nameValidState}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <NewTextInput
                                        controlId="form-website-url"
                                        label="Website URL"
                                        placeholder="https://apple.com"
                                        value={state.url}
                                        onChange={handleUrlChanged}
                                        validationState={state.validation.urlValidState}
                                    />
                                </Col>
                            </BootstrapRowColCombo>
                            <BootstrapRowColCombo>
                                <Col xs={6} lg={6}>
                                    <NewAdvanceDropdown
                                        options={admins.filter(x => x.is_account_manager === true).map(x => ({ id: x.id, name: x.user.name }))}
                                        value={state.adminId}
                                        onChange={handleAccountManagerChanged}
                                        placeholder="Select"
                                        label="Account manager" />
                                </Col>
                                <Col xs={6} lg={6}>
                                    <NewAdvanceDropdown
                                        options={typeOfPlans}
                                        value={state.typeOfPlan.toString()}
                                        onChange={handleTypeOfPlanChanged}
                                        label="Default Role Type" />

                                </Col>
                            </BootstrapRowColCombo>
                            <BootstrapRowColCombo>
                                <Col xs={12}>
                                    <NewTextInput
                                        controlId="form-notes"
                                        label="Notes:"
                                        componentClass="textarea"
                                        value={state.notes}
                                        onChange={handleNotesChanged}
                                        helpText="Max 1000 chars"
                                        validationState={state.validation.notesValidState}
                                    />
                                </Col>
                            </BootstrapRowColCombo>
                        </FormPanel>
                    </BootstrapRowColCombo>
                    <Row>
                        <Col xs={2} lgOffset={7}>
                            {/*<Button type="reset" block bsStyle="danger">Cancel</Button>*/}
                        </Col>
                        <Col xs={3}>
                            <Button disabled={state.submitDisabled} type="submit" block bsStyle="success">
                                Create Customer
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </GreyScrollBoxArea>
        </Fragment>
    )
};

export default AddCompanySection;
