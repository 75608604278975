import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './PlanCard.module.css';

const PlanCard = ({
    costPerCandidateInDollars, 
    candidateVolume, 
    billingRenewalDate, 
    planName, 
    planId,
    showDelete=false, 
    showEdit=false,
    onDeleteClicked=() => {},
    onEditClicked=() => {}
}) => {

    const isFreemiumAccount = costPerCandidateInDollars === 0;

    const handleEditClicked = () => {
        if (!planId) {
            console.error('Error getting planId in PlanCard');
            return;
        }
        onEditClicked(planId);
    }

    const handleDeleteClicked = () => {
        if(!planId) {
            console.error('Error getting planId in PlanCard');
            return;
        }
        onDeleteClicked(planId);
    }
    
    return (
        <div className={styles.planCard}>
            {showDelete && (
                <button 
                    onClick={handleDeleteClicked} 
                    className={styles.deleteButton}
                    aria-label="Delete Plan"
                    title="Delete Plan"
                >
                    &times;
                </button>
            )}

            <Row className="justify-content-between">
                <Col>
                    <p className={styles.planName}>
                        <span className={styles.label}>Plan Name: </span> 
                        <span className={styles.value}>{planName}</span>
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-between">
                <Col>
                    <p className={styles.cost}>
                        <span className={styles.label}>Cost Per Candidate: </span> 
                        <span className={styles.value}>${costPerCandidateInDollars}</span>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className={styles.candidateVolume}>
                        <span className={styles.label}>Candidate Volume: </span> 
                        <span className={styles.value}>{candidateVolume} / month</span>
                    </p>
                </Col>
            </Row>
            {(!isFreemiumAccount && billingRenewalDate) && (
                <Row>
                    <Col>
                        <p className={styles.renewalDate}>
                            <span className={styles.label}>To be renewed on: </span> 
                            <span className={styles.value}>{billingRenewalDate}</span>
                        </p>
                    </Col>
                </Row>
            )}

            {showEdit && (
                <button 
                    onClick={handleEditClicked} 
                    className={styles.editButton}
                    aria-label="Edit Plan"
                >
                    Edit
                </button>
            )}
        </div>
    )
}

export default PlanCard;
