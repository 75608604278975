import _ from 'lodash';

export const FALLBACK_TALENT_IMAGE = 'https://s3-us-west-2.amazonaws.com/rolebot.io/static/logo-square.png';

/**
 * Compare two objects and returns the original item that is now changed.
 * @param object
 * @param base
 * @returns object
 */
export const objectDiff = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

/**
 * Compare two objects and returns the item that is now changed.
 * @param object
 * @param base
 * @returns object
 */
export const getObjectDiff = (object, base) => {
	function changes(object, base) {
		return _.transform(object, function(result, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : base[key];
			}
		});
	}
	return changes(object, base);
}


export const getProfileRegex = (profileName) => {
	switch(profileName.toLowerCase()) {
		case 'twitter': 
			return '^.*twitter.com/([a-zA-Z0-9_-]+)';
		case 'linkedin':
			return '^.*linkedin.com/in/([a-zA-Z0-9_-]+)';
		case 'github':
			return '^.*github.com/([a-zA-Z0-9_-]+)';
		case 'stackoverflow':
			return '^.*stackoverflow.com/[a-zA-Z]+/([0-9]+)/[a-zA-Z0-9_-]+';
		case 'meetup':
			return '^.*meetup.com/.*members/([0-9]+)';
		case 'hackerrank':
			return '^.*hackerrank.com/([a-zA-Z0-9_-]+)';
		case 'angel':
			return '^.*angel.co/([a-zA-Z0-9_-]+)';
		case 'kaggle':
			return '^.*kaggle.com/([a-zA-Z0-9_-]+)';
		case 'quora':
			return '^.*quora.com/profile/([a-zA-Z0-9_-]+)';
		default:
			return null; 
	}
}



export const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export const centsToDollars = (cents) => {
    return (cents / 100).toFixed(2);
}

export const dollarsToCents = (dollars) => {
    return Math.round(dollars * 100);
}
